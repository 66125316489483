import React, { useState } from 'react';
import LayoutHeaderFooter from '../../components/LayoutHeaderFooter/LayoutHeaderFooter';
import Button from '../../components/Button/Button';
import FirstComponentSmall from '../../components/FirstComponentSmall/FirstComponentSmall';
import ClockLoader from 'react-spinners/ClockLoader';
import { css } from '@emotion/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SuccessCheckMark from '../../components/SuccessCheckMark/SuccessCheckMark';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ReCAPTCHA from 'react-google-recaptcha';
import recaptchaRef from './recaptchaRef';
import CMOCheckbox from './Checkbox/Checkbox';
import TextField from '@material-ui/core/TextField';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import './contactForm.scss';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactForm = (props) => {
  const {
    title,
    description,
    primary_color,
    secondary_color,
    slug,
    SEO
  } = props.pageContext.content;
  const [surname, setSurname] = useState('');
  const [surnameError, setSurnameError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [adatV, setAdatV] = useState(false);
  const [adatVError, setAdatVError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [snackbar, setSnackBar] = useState(false);
  const [snackbarError, setSnackBarError] = useState(false);

  const override = css`
    display: block;
    margin: 0 auto;
    margin-bottom: 50px;
    box-shadow: none;
    border: 4px solid #4caf50;
  `;

  const getResult = () => {
    recaptchaRef.current.execute();
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
    setSnackBarError(false);
  };

  const surNameValidation = () => {
    if (surname === '') {
      setSurnameError(true);
      return false;
    } else {
      setSurnameError(false);
      return true;
    }
  };

  const firstNameValidation = () => {
    if (firstName === '') {
      setFirstNameError(true);
      return false;
    } else {
      setFirstNameError(false);
      return true;
    }
  };

  const phoneValidation = () => {
    if (phoneNumber === '') {
      setPhoneNumberError(true);
      return false;
    } else {
      setPhoneNumberError(false);
      return true;
    }
  };

  const emailCheck = () => {
    if (email === '') {
      setEmailError('Kötelezően kitöltendő');
      return false;
    } else {
      if (emailValidation()) {
        setEmailError('');
        return true;
      } else {
        setEmailError('Nem megfelelő formátum');
        return false;
      }
    }
  };

  const emailValidation = () => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  };

  const adatVedelmiValidation = () => {
    if (adatV === false) {
      setAdatVError(true);
      return false;
    } else {
      setAdatVError(false);
      return true;
    }
  };

  const formvalidations = async () => {
    let surNameResult = await surNameValidation();
    let firstNameResult = await firstNameValidation();
    let phoneResult = await phoneValidation();
    let emailResult = await emailCheck();
    let adatVedelemiResult = await adatVedelmiValidation();

    if (
      firstNameResult === false ||
      surNameResult === false ||
      emailResult === false ||
      phoneResult === false ||
      adatVedelemiResult === false
    ) {
      setEmailSuccess(false);
      return false;
    } else {
      return true;
    }
  };

  const convert = (slug) => {
    switch (slug) {
      case '/erintesmentes-fizetes':
        return 'ContactlessPayment';
      case '/kapcsolat':
        return 'Connection';
      case '/online-szamlazo':
        return 'Invoicing';
      case '/penztargep':
        return 'CashRegister';
      default:
        break;
    }
  };

  const onChange = (token) => {
    setLoader(true);

    fetch(`${process.env.GATSBY_AZURE_URL}/contract-form`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        recaptcha: token,
        surname,
        firstName,
        phoneNumber,
        email,
        interestFor: convert(slug)
      })
    })
      .then((data) => {
        if (data.status === 200) {
          setLoader(false);
          setEmailSuccess(true);
        } else {
          setLoader(false);
          setEmailSuccess(false);
          setSnackBarError(true);
        }
      })
      .catch((e) => {
        setLoader(false);
        setEmailSuccess(false);
        setSnackBarError(true);
      });
  };
  const location_href = props.location.pathname;
  return (
    <div className="contactForm-wrapper">
      <LayoutHeaderFooter
        header_background_color={secondary_color}
        header_secondary_color={primary_color}
        SEO_title={SEO.title}
        SEO_description={SEO.description}
        SEO_keywords={SEO.keywords}
        SEO_location={location_href}
      >
        <FirstComponentSmall
          primary_color={primary_color}
          secondary_color={secondary_color}
          title={title}
          subtitle={description}
          onClick={() => {}}
        />
        <div className="body-content">
          <LayoutComponent background_color="#F7F7FA">
            <section>
              <div className="form">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY}
                  onChange={onChange}
                />
                <h2>Kérd visszahívásunkat!</h2>
                <TextField
                  label={'Vezetéknév'}
                  variant="filled"
                  error={!surnameError ? false : true}
                  onChange={(e) => {
                    setSurname(e.target.value);
                    setSurnameError(false);
                    setEmailSuccess(false);
                  }}
                />
                <TextField
                  label={'Keresztnév'}
                  variant="filled"
                  error={!firstNameError ? false : true}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setFirstNameError(false);
                    setEmailSuccess(false);
                  }}
                />
                <TextField
                  label={'Telefonszám'}
                  variant="filled"
                  error={!phoneNumberError ? false : true}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    setPhoneNumberError(false);
                    setEmailSuccess(false);
                  }}
                />
                <TextField
                  label={'Email cím'}
                  variant="filled"
                  error={!emailError ? false : true}
                  helperText={!emailError ? ' ' : emailError}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                    setEmailSuccess(false);
                  }}
                />
                <div
                  className={adatVError ? 'accept-adatv found' : 'accept-adatv'}
                >
                  <FormControlLabel
                    control={
                      <CMOCheckbox
                        onChange={(e) => {
                          setAdatVError(false);
                          if (e.target.checked) {
                            setAdatV(true);
                          } else {
                            setAdatV(false);
                          }
                        }}
                      />
                    }
                    label={
                      <div className="accept-adatv-text">
                        Elolvastam és elfogadom az{' '}
                        <a
                          href={'https://cmo.hu/adatkezelesi_tajekoztato.pdf'}
                          target={'__blank'}
                          rel={'noopener noreferrer nofollow'}
                        >
                          Adatkezelési tájékoztatót
                        </a>
                      </div>
                    }
                  />
                </div>
                <Button
                  variant="contained"
                  background_color={'#5107FF'}
                  text={'Érdeklődés elküldése'}
                  color={'white'}
                  onClick={async () => {
                    if (await formvalidations()) {
                      getResult();
                      // onChange();
                    }
                  }}
                ></Button>
                <div className="footNote">
                  Elküldés után szakértő kollégánk hamarosan keresni fog a
                  megadott telefonszámon.
                </div>
                <div>
                  {loader ? (
                    <ClockLoader
                      loading={loader}
                      css={override}
                      size={88}
                      color={'#4CAF50'}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {emailSuccess ? (
                    <SuccessCheckMark
                      id={`id_${convert(slug)}`}
                      text={
                        'Sikeres elküldés!\nKöszönjük az érdelődésed, kollégánk 2 munkanapon belül felveszi veled a kapcsolatot.'
                      }
                    />
                  ) : null}
                  <Snackbar
                    open={snackbarError}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    style={{ paddingTop: '100px' }}
                    autoHideDuration={6000}
                    onClose={handleSnackBarClose}
                  >
                    <Alert onClose={handleSnackBarClose} severity="error">
                      Hiba történt!
                    </Alert>
                  </Snackbar>
                </div>
              </div>
              <div id="recaptcha">
                This site is protected by reCAPTCHA and the Google{' '}
                <span>
                  <a href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{' '}
                </span>
                and{' '}
                <span>
                  <a href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{' '}
                </span>
                apply.
              </div>
            </section>
            <section>
              <h2>Vagy szeretnél inkább Te megkeresni&nbsp;minket?</h2>
              <ul>
                <li>
                  <a
                    className="contact"
                    href={`tel:+ 3614433923`}
                    target={'__blank'}
                    rel={'noopener noreferrer nofollow'}
                  >
                    <PhoneIcon />
                    <div className="contact-content">
                      <p>+ 36 1 443 39 23</p>
                      <p className="sub">hétfőtől péntekig 9-16 óráig</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="contact"
                    href={`mailto:info@cmo24.com`}
                    target={'__blank'}
                    rel={'noopener noreferrer nofollow'}
                  >
                    <MailIcon />
                    <div className="contact-content">
                      <p>info@cmo24.com</p>
                      <p className="sub">hétfőtől péntekig 9-16 óráig</p>
                    </div>
                  </a>
                </li>
              </ul>
            </section>
          </LayoutComponent>
        </div>
      </LayoutHeaderFooter>
    </div>
  );
};

export default ContactForm;
